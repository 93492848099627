<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="calories"
    class="ma-2 elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>ПОРОДЫ</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Новый</v-btn
            >
          </template>
          <v-card>
            <v-form ref="form" v-model="formValidity" @submit.prevent="save">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-text-field v-model="editedItem.name" label="Название" />
                  <v-select
                    :items="desserts"
                    v-model="editedItem.parent_id"
                    item-text="name"
                    item-value="id"
                    label="Категория"
                    :rules="requiredRules"
                    clearable
                    required
                  />
                  <v-checkbox
                    v-model="editedItem.redbook"
                    label="Краснокнижные"
                  ></v-checkbox>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
                <v-btn
                  type="submit"
                  color="blue darken-1"
                  :disabled="!formValidity"
                  >Сохранить</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Обновить</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BreedsTab",

  data: () => ({
    inputName: "",
    dialog: false,
    headers: [
      {
        text: "Название",
        align: "start",
        value: "name"
      },
      {
        text: "Краснокнижные",
        align: "start",
        value: "redbook"
      },
      { text: "Действия", value: "actions", sortable: false, width: 100 }
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      parent_id: "",
      redbook: false
    },
    defaultItem: {
      name: "",
      parent_id: "",
      redbook: false
    },
    requiredRules: [value => !!value || "Обязательное поле"],
    formValidity: false
  }),

  computed: {
    ...mapGetters(["allBreedsData"]),
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    desserts() {
      let arr = [];
      this.allBreedsData.forEach(e => {
        let name = e.name;
        let redbook = e.redbook;
        if (e.parent) {
          let parent = e.parent;
          name = parent.name + " - " + name;
          if (parent.parent) {
            name = parent.parent.name + " - " + name;
          }
        }
        if (e.redbook) redbook = "Да";
        if (!e.redbook) redbook = "Нет";
        return arr.push({
          id: e.id,
          name: name,
          parent_id: e.parent_id,
          redbook: redbook
        });
      });
      return arr;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions([
      "getBreedsData",
      "destroyBreedData",
      "createBreedData",
      "pushBreedData"
    ]),
    initialize() {
      this.getBreedsData();
    },
    save() {
      if (this.editedIndex > -1) {
        this.pushBreedData({
          id: this.editedItem.id,
          name: this.editedItem.name,
          parent_id: this.editedItem.parent_id,
          redbook: this.editedItem.redbook
        });
      } else {
        this.createBreedData({
          name: this.editedItem.name,
          parent_id: this.editedItem.parent_id,
          redbook: this.editedItem.redbook
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, this.allBreedsData[this.editedIndex]);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  }
};
</script>

<style scoped></style>
